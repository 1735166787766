import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { options } from './Quote.constants';
import { StyledQuote } from './Quote.styles';

import { QuoteProps } from './models.d';

const Quote = ({ quote }: QuoteProps) => (
  <StyledQuote data-testid="quote">{renderRichText(quote, options)}</StyledQuote>
);

export default Quote;
