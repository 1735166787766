import { TypographyProps } from 'components/atoms/Typography';

export const titleVariant: TypographyProps = {
  size: { base: 24, md: 36, lg: 48 },
  color: 'gray-25',
  letterSpacing: '-0.02em',
  asEl: 'h2',
  fontWeight: 500,
  align: { base: 'left', lg: 'center' },
};

export const descriptionVariant: TypographyProps = {
  size: { base: 16, lg: 18 },
  color: 'gray-100',
  fontWeight: 300,
  align: { base: 'left', lg: 'center' },
};
