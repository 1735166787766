import styled from 'styled-components';
import { breakpoint } from 'styles';

export const StyledQuote = styled.cite`
  z-index: var(--z-index-ground);
  margin-bottom: var(--gap-xxs);

  ${breakpoint.md} {
    margin-bottom: var(--gap-xxl);
  }

  ${breakpoint.lg} {
    margin-top: 56px;
    margin-bottom: 136px;
  }

  ${breakpoint.xl} {
    margin-top: 0;
    margin-bottom: 216px;
  }

  strong::before {
    display: block;
    content: '';

    ${breakpoint.md} {
      display: none;
    }
  }
`;
