import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import Typography from 'components/atoms/Typography';

export const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <Typography
        align="center"
        fontWeight={500}
        size={{ base: 20, md: 24, lg: 36 }}
        color="gray-900"
        asEl="strong"
      >
        {text}
      </Typography>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Typography
        align="center"
        fontWeight={400}
        size={{ base: 14, md: 20, lg: 24 }}
        color="gray-700"
        asEl="p"
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Typography
        align="center"
        size={{ base: 18, md: 24, lg: 36 }}
        fontWeight={300}
        color="gray-900"
        asEl="h2"
        letterSpacing="-0.02em"
        padding={{ bottom: { base: 8, md: 24 } }}
      >
        {children}
      </Typography>
    ),
  },
};
