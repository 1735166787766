import React from 'react';
import { graphql } from 'gatsby';

import Quote from 'components/atoms/Quote';
import Container from 'components/molecules/Container';
import Banner from 'components/organisms/Banner';
import BenefitsSection from 'components/organisms/BenefitsSection';
import RelatedList, { RelatedListVariant } from 'components/organisms/RelatedList';
import ServicesSection from 'components/organisms/ServicesSection';
import TechnologiesSection, {
  TechnologiesSectionVariant,
} from 'components/organisms/TechnologiesSection';
import Timeline from 'components/organisms/Timeline';
import ValuesSection from 'components/organisms/ValuesSection';
import {
  StyledOurCaseStudiesContainer,
  StyledQuoteContainer,
} from 'components/templates/containers';
import Layout from 'components/templates/Layout';

const ServicesDetailPage = ({
  data: {
    contentfulServicesDetailPage: {
      banner,
      headingImage,
      featureList,
      benefitsSection,
      quote,
      advantagesList,
      timeline,
      technologies,
      pageName,
      relatedList: { title, proportions, relatedSites, button },
      seo,
    },
  },
}: ServicesDetailPageTypes.ServicesDetailPageProps) => (
  <Layout seo={seo} bgColor="gray-50">
    {banner ? <Banner {...{ ...banner, headingImage }} /> : null}
    {featureList ? (
      <Container contentWidth={1280} mt={{ base: 20, md: -10, lg: 10 }}>
        <ServicesSection {...featureList} />
      </Container>
    ) : null}
    {benefitsSection ? (
      <Container
        contentWidth={1280}
        mb={{ base: 80, md: 96, lg: 136, xl: 200 }}
        mt={{ base: 32, md: 80, lg: 200 }}
      >
        <ValuesSection {...benefitsSection} />
      </Container>
    ) : null}
    {quote ? (
      <StyledQuoteContainer contentWidth={740}>
        <Quote {...{ quote }} />
      </StyledQuoteContainer>
    ) : null}
    {advantagesList ? (
      <Container mb={{ base: 64 }} mt={{ base: 64 }} contentWidth={1280}>
        <BenefitsSection {...advantagesList} />
      </Container>
    ) : null}
    {technologies ? (
      <TechnologiesSection
        {...technologies}
        variant={TechnologiesSectionVariant.SERVICES}
        whichPage={pageName}
      />
    ) : null}
    {timeline ? <Timeline {...timeline} whichPage={pageName} /> : null}
    <StyledOurCaseStudiesContainer
      pb={{ base: 80, md: 96, lg: 136, xl: 200 }}
      mt={{ base: 80, md: 96, lg: 136, xl: 200 }}
      pt={{ base: 40 }}
      showSectionCut={featureList?.whichPage !== 'services'}
    >
      <RelatedList
        heading={title}
        list={relatedSites}
        proportions={proportions}
        variant={RelatedListVariant.SECONDARY}
        button={button?.[0]}
        addExtraPadding
      />
    </StyledOurCaseStudiesContainer>
  </Layout>
);

export default ServicesDetailPage;

export const query = graphql`
  query ServicesDetailPageQuery($slug: String) {
    contentfulServicesDetailPage(slug: { eq: $slug }) {
      pageName
      seo {
        ...SeoFragment
      }
      banner {
        ...BannerFragment
      }
      headingImage {
        ...ImageDataStructureFragment
      }
      featureList {
        ...FeatureSectionFragment
      }
      benefitsSection {
        ...FeatureSectionFragment
      }
      quote {
        raw
      }
      advantagesList {
        ...FeatureSectionFragment
      }
      teamCarousel {
        ... on ContentfulRelatedList {
          ...RelatedListFragment
        }
      }
      timeline {
        ...TimelineFragment
      }
      relatedList {
        ...RelatedListFragment
      }
      technologies {
        ...TechnologiesSectionFragment
      }
    }
  }
`;
