import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import GlassTile from 'components/atoms/GlassTile';
import Typography, { TypographyProps } from 'components/atoms/Typography';
import Feature from 'components/molecules/Feature';

import { useScreenService } from 'hooks/useScreenService';

import { descriptionVariant, titleVariant } from './ValuesSection.constants';
import { StyledButton, StyledIntro, StyledList, StyledWrapper } from './ValuesSection.styles';

import { ValuesSectionProps } from './models.d';

const ValuesSection = ({ whichPage, title, description, features, button }: ValuesSectionProps) => {
  const { isLgUp } = useScreenService();

  const renderButton = (
    <StyledButton
      {...button}
      typography={{
        size: { base: 16, lg: 18 },
      }}
    />
  );

  const isOutsourcing = whichPage === 'outsourcing';
  const isUxUi = whichPage === 'ux-ui';

  const customTextAlign = (isOutsourcing || isUxUi) && {
    align: 'left' as TypographyProps['align'],
  };

  return features?.length ? (
    <StyledWrapper
      as={GlassTile}
      data-glass-variant="dark"
      aria-labelledby="values"
      data-page={whichPage}
    >
      {title || description || button ? (
        <StyledIntro>
          {title ? (
            <Typography id="values" {...{ ...titleVariant, ...customTextAlign }}>
              {title}
            </Typography>
          ) : null}
          {description ? (
            <Typography {...{ ...descriptionVariant, ...customTextAlign }}>
              {renderRichText(description)}
            </Typography>
          ) : null}
          {button && isLgUp ? renderButton : null}
        </StyledIntro>
      ) : null}
      <StyledList>
        {features.map((feature, index) => (
          <Feature key={feature.title} {...feature} order={index} />
        ))}
      </StyledList>
      {button && !isLgUp ? renderButton : null}
    </StyledWrapper>
  ) : null;
};

export default ValuesSection;
