import styled from 'styled-components';
import { breakpoint } from 'styles';

import Button from 'components/atoms/Button';

export const StyledButton = styled(Button)`
  align-self: end;
  text-align: right;

  ${breakpoint.lg} {
    align-self: start;
  }
`;

export const StyledIntro = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);
`;

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);

  ${breakpoint.md} {
    gap: var(--gap-md) var(--gap-sm);
  }

  ${breakpoint.lg} {
    gap: var(--gap-xxxxxl) var(--gap-xxxxl);
  }
`;

export const StyledWrapper = styled.section`
  position: relative;
  z-index: var(--z-index-two);
  display: flex;
  flex-direction: column;
  padding: var(--gap-sm);

  ${breakpoint.md} {
    padding: var(--gap-md) var(--gap-xl);
  }

  ${breakpoint.lg} {
    padding: var(--gap-xl) var(--gap-xxl);
  }

  ${breakpoint.xl} {
    padding: var(--gap-xxxl) var(--gap-xxxxxl);
  }

  /* UX UI */
  &[data-page='ux-ui'] {
    ${breakpoint.lg} {
      flex-direction: row;
      gap: var(--gap-sm);
      align-items: center;

      & > * {
        flex-basis: 50%;
      }
    }

    ${StyledIntro} {
      margin-bottom: var(--gap-lg);

      ${breakpoint.lg} {
        margin: 0;
      }
    }

    ${StyledList} {
      margin-bottom: var(--gap-lg);

      ${breakpoint.md} {
        display: grid;
        grid-template-areas:
          'f0 f1'
          'f2 f3';
        grid-template-columns: repeat(2, 1fr);
      }

      ${breakpoint.lg} {
        margin: 0;
      }
    }
  }

  /* Outsourcing */
  &[data-page='outsourcing'] {
    ${breakpoint.xl} {
      flex-direction: row;
      gap: var(--gap-sm);
      align-items: center;

      & > * {
        flex-basis: 50%;
      }
    }

    ${StyledIntro} {
      margin-bottom: var(--gap-lg);

      ${breakpoint.md} {
        margin-bottom: var(--gap-sm);
      }
    }

    ${StyledList} {
      ${breakpoint.md} {
        display: grid;
        grid-template-areas:
          'f0 f1'
          'f2 f3';
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  /* Content migration */
  &[data-page='content-migration'] {
    ${StyledIntro} {
      margin-bottom: var(--gap-lg);

      ${breakpoint.md} {
        margin-bottom: var(--gap-sm);
      }

      ${breakpoint.lg} {
        margin-bottom: var(--gap-xl);
      }
    }

    ${StyledList} {
      ${breakpoint.md} {
        display: grid;
        grid-template-areas:
          'f0 f1'
          'f2 f3'
          'f4 f5';
        grid-template-columns: repeat(2, 1fr);
      }

      ${breakpoint.lg} {
        grid-template-areas:
          'f0 f1 f2'
          'f3 f4 f5';
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  /* Salesforce */
  &[data-page='salesforce'],
  &[data-page='react'] {
    ${StyledIntro} {
      margin-bottom: var(--gap-lg);

      ${breakpoint.md} {
        margin-bottom: var(--gap-sm);
      }

      ${breakpoint.lg} {
        margin-bottom: var(--gap-xxxl);
      }
    }

    ${StyledList} {
      ${breakpoint.lg} {
        display: grid;
        grid-template-areas: 'f0 f1 f2';
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  /* Services Listing */
  &[data-page='services'] {
    ${StyledIntro} {
      margin-bottom: var(--gap-lg);

      ${breakpoint.md} {
        margin-bottom: var(--gap-sm);
      }

      ${breakpoint.lg} {
        margin-bottom: var(--gap-xl);
      }
    }

    ${StyledList} {
      ${breakpoint.md} {
        display: grid;
        grid-template-areas:
          'f0 f1'
          'f2 f3';
        grid-template-columns: repeat(2, 1fr);
      }

      ${breakpoint.xl} {
        grid-template-areas: 'f0 f1 f2 f3';
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
`;
